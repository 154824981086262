import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(public snackBar: MatSnackBar) {}

  success(message: string): void {
    this.snackBar.open(message, '', {
      panelClass: ['snackbar--success'],
      duration: 3000,
    });
  }

  error(message: string): void {
    this.snackBar.open(message, 'X', {
      panelClass: ['snackbar--error'],
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
    });
  }
}
