import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, computed } from '@angular/core';
import { Theme } from '@core/enums/theme';
import { ThemeService } from '@core/services/theme.service';
import { ParticlesComponent } from '@shared/components/particles/particles.component';

@Component({
  selector: 'app-layout-auth',
  standalone: true,
  templateUrl: './layout-auth.component.html',
  imports: [CommonModule, NgOptimizedImage, ParticlesComponent],
})
export class LayoutAuthComponent {
  thumbnail = computed(() =>
    this.theme.getTheme() === Theme.DARK
      ? './assets/images/flowzilla_gr24_branding_invert.svg'
      : './assets/images/flowzilla_gr24_branding.svg'
  );

  constructor(private theme: ThemeService) {}
}
