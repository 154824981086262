import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SidebarService } from '@core/components/sidebar/service/sidebar.service';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  standalone: true,
  selector: 'app-sidebar-item',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    RouterLink,
    RouterLinkActive,
    MatTooltipModule,
    FontAwesomeModule,
  ],
  styles: [
    `
      .iconD {
        display: flex;
        align-items: center;
        margin: auto;
        @apply group-hover:text-primary-500;
      }
      .expanded {
        @apply group-hover:text-primary-500;
        display: flex;
        align-items: center;
        justify-content: start;
        padding-left: 8px;
      }
    `,
  ],
  template: `
    <li
      class="my-1"
      matTooltip="{{ label() }}"
      [matTooltipDisabled]="!collapsed()"
      matTooltipShowDelay="300"
      matTooltipPosition="right">
      <a
        class="group flex items-center gap-x-3 rounded py-2 font-normal transition-all duration-100 hover:bg-primary-50 hover:text-primary-500 hover:ring-1 hover:ring-primary-500 dark:hover:bg-primary-950 dark:hover:ring-primary-500/80"
        [ngClass]="{
          'aspect-square': collapsed(),
          'justify-center': collapsed(),
        }"
        routerLink="/{{ path() }}"
        routerLinkActive="dark:bg-primary-950 bg-primary-50 text-primary-500 ring-1 border-primary-500 ring-primary-500 dark:border-primary-500/80 hover:ring-0"
        [routerLinkActiveOptions]="{ exact: false }">
        <fa-icon
          routerLinkActive="text-primary-500"
          [ngClass]="collapsed() ? 'iconD' : 'expanded'"
          [icon]="icon()" />
        <span [hidden]="collapsed()">{{ label() }}</span>
      </a>
    </li>
  `,
})
export class SidebarItemComponent {
  icon = input.required<IconProp>();
  label = input.required<string>();
  path = input.required<string>();

  collapsed = toSignal(this.sidebarService.sidebarCollapsed$);
  constructor(private sidebarService: SidebarService) {}
}
