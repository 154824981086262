import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { map, Observable } from 'rxjs';
import { AuthUser } from '@core/states/auth/interfaces/auth-user';
import { UsersApi } from '@shared/api/users.api';
import { SkipErrorRedirectHeader } from '@core/interceptors/error.interceptor';

@Injectable({
  providedIn: 'root',
})
export class AuthApi {
  get authUrl(): string {
    return `${environment.apiConfUrl}/auth`;
  }

  get usersUrl(): string {
    return `${environment.apiConfUrl}/users`;
  }
  constructor(
    private http: HttpClient,
    private usersApi: UsersApi
  ) {}

  login(
    email: string,
    password: string,
    rememberMe: boolean = false
  ): Observable<AuthUser> {
    return this.http
      .post(`${this.authUrl}/login`, {
        username: email,
        password: password,
        _remember_me: rememberMe,
      })
      .pipe(map((response: any) => response as AuthUser));
  }

  getUser(skipErrorRedirect = false): Observable<AuthUser> {
    return this.http
      .get(`${this.authUrl}/get-user`, {
        headers: skipErrorRedirect ? SkipErrorRedirectHeader : {},
      })
      .pipe(map((response: any) => response as AuthUser));
  }

  logout(): Observable<void> {
    return this.http
      .post(`${this.authUrl}/logout`, {})
      .pipe(map((response: any) => response as void));
  }

  updatePassword(
    id: number,
    data: { oldPassword: string; newPassword: string }
  ): Observable<AuthUser> {
    const input = {
      oldPassword: data.oldPassword,
      password: data.newPassword,
    } as any;
    return this.http
      .post(`${this.usersUrl}/${id}/password-change`, input)
      .pipe(map((response: any) => response as AuthUser));
  }

  updateUser(data: AuthUser): Observable<AuthUser> {
    return this.usersApi.update(data);
  }
}
