import { Component } from '@angular/core';
import { SidebarService } from '@core/components/sidebar/service/sidebar.service';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  MatDrawer,
  MatDrawerContainer,
  MatDrawerContent,
} from '@angular/material/sidenav';
import { SidebarContentComponent } from '@core/components/sidebar/components/sidebar-content.component';

@Component({
  standalone: true,
  selector: `app-sidebar`,
  imports: [
    MatDrawer,
    MatDrawerContainer,
    MatDrawerContent,
    SidebarContentComponent,
  ],
  styles: `
    .mat-drawer-side {
      border: 0;
      &.opened {
        @apply min-w-72;
      }
      &.collapsed {
        @apply max-w-16;
      }
    }
  `,
  template: `
    <mat-drawer-container class="h-content" autosize>
      <mat-drawer
        class="z-50"
        [class.opened]="!collapsed()"
        [class.collapsed]="collapsed()"
        [opened]="true"
        [mode]="'side'"
        disableClose>
        <app-sidebar-content />
      </mat-drawer>

      <mat-drawer-content class="bg--default">
        <ng-content></ng-content>
      </mat-drawer-content>
    </mat-drawer-container>
  `,
})
export class SidebarComponent {
  /**
   * PROPERTIES
   */
  collapsed = toSignal(this.sidebarService.sidebarCollapsed$);

  constructor(private sidebarService: SidebarService) {}
}
