import { Component } from '@angular/core';
import { ParticleComponent } from './particle.component';
import { CommonModule } from '@angular/common';
import { RandomSizeDirective } from '@shared/directives/random-size.directive';

@Component({
  standalone: true,
  selector: 'app-particles',
  template: `
    <div class="absolute bottom-0 left-0 right-0 top-0 z-10 size-full">
      @for (particle of particles; track $index) {
        <app-particle appRandomSize />
      }
    </div>
  `,
  imports: [ParticleComponent, CommonModule, RandomSizeDirective],
})
export class ParticlesComponent {
  particles = Array.from({ length: 200 });

  constructor() {}

  randomDuration(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
}
