import { HttpClient } from '@angular/common/http';
import {
  ApiOptions,
  ApiOptionsFilter,
  ApiOptionsSort,
} from '@core/services/api/interfaces/api-options';
import { PaginatedResult } from '@core/services/api/interfaces/paginated-result';
import { environment } from '@environment/environment';
import { map, Observable } from 'rxjs';
import { ApiResponse } from '@core/services/api/interfaces/api-response';
import { FilterParser } from '@core/services/api/filter/filter-parser';
import { PropertyFilters } from '@core/services/api/models/property-filter';
import { FilterOperator } from '@core/services/api/filter/enums/filter-operator';
import { DateFilterOperator } from '@core/services/api/filter/enums/date-filter-operator';
import { Identifiable } from '@core/interfaces/identifiable';

export abstract class ApiService<T extends Identifiable> {
  abstract readonly endpoint: string;
  readonly apiUrl = environment.apiCoreUrl;

  get url(): string {
    return `${this.apiUrl}/${this.endpoint}`;
  }

  get actionUrl(): string {
    return `${this.apiUrl}/actions/${this.endpoint}`;
  }

  constructor(public http: HttpClient) {}

  index(
    options?: ApiOptions,
    includeEntities: string[] | null = null,
    propertyFilters: PropertyFilters<T> | null = null
  ): Observable<PaginatedResult<T>> {
    const query = this.buildQueryString(
      options,
      includeEntities,
      propertyFilters
    );
    return this.http
      .get<ApiResponse<T>>(`${this.url}?${query}`)
      .pipe(
        map((response: ApiResponse<T>) => this.createPaginatedResult(response))
      );
  }

  show(id: number, includeEntities: string[] | null = null): Observable<T> {
    return this.http.get<T>(this.getEntityUrl(id, includeEntities));
  }

  create(data: T, includeEntities: string[] | null = null): Observable<T> {
    console.log('create', data);
    return this.http.post<T>(this.getUrl(includeEntities), data);
  }

  update(data: T, includeEntities: string[] | null = null): Observable<T> {
    return this.http.put<T>(this.getEntityUrl(data.id, includeEntities), data);
  }

  delete(id: number, includeEntities: string[] | null = null): Observable<any> {
    return this.http.delete(this.getEntityUrl(id, includeEntities));
  }

  clone(
    id: number,
    data: Partial<T>,
    includeEntities: string[] | null = null
  ): Observable<T> {
    return this.http.post<T>(
      `${this.getEntityUrl(id, includeEntities, 'copy')}`,
      data
    );
  }

  import(
    data: Partial<T>,
    includeEntities: string[] | null = null
  ): Observable<T> {
    return this.http.post<T>(this.getUrl(includeEntities, 'import'), data);
  }

  distinct(field: string, options: any): Observable<string[]> {
    const query = this.buildQueryString(options, null, null);
    return this.http.get<string[]>(
      `${this.actionUrl}/distinct/${field}${query ? `?${query}` : ''}`
    );
  }

  count(filter?: ApiOptionsFilter): Observable<number> {
    const query = filter ? ApiService.toQueryPropertyString(filter) : undefined;
    return this.http.get<number>(
      `${this.actionUrl}/count${query ? `?${query}` : ''}`
    );
  }

  private buildIncludeEntitiesQuery(includeEntities: string[] | null): string {
    if (includeEntities === null) {
      return '';
    }
    return includeEntities.length > 0
      ? `includeEntities=${encodeURIComponent(JSON.stringify(includeEntities))}`
      : 'includeEntities';
  }

  private buildPropertyFiltersQuery(
    propertyFilters: PropertyFilters<any> | null
  ): string {
    if (!propertyFilters?.length) {
      return '';
    }

    const prefix = 'properties';

    return propertyFilters
      .map(propertyFilter => propertyFilter.extract(prefix))
      .join('');
  }

  protected getEntityUrl(
    id: number,
    includeEntities: string[] | null,
    action: string | null = null
  ): string {
    const includeEntitiesQuery =
      this.buildIncludeEntitiesQuery(includeEntities);
    return `${action ? this.actionUrl : this.url}/${id}${action ? `/${action}` : ''}${includeEntitiesQuery ? `?${includeEntitiesQuery}` : ''}`;
  }

  protected getUrl(
    includeEntities: string[] | null,
    action: string | null = null
  ): string {
    const includeEntitiesQuery =
      this.buildIncludeEntitiesQuery(includeEntities);
    return `${action ? `${this.actionUrl}/${action}` : this.url}${includeEntitiesQuery ? `?${includeEntitiesQuery}` : ''}`;
  }

  protected buildQueryString(
    options: ApiOptions | null = null,
    includeEntities: string[] | null,
    propertyFilters: PropertyFilters<T> | null
  ): string {
    const queryParts: string[] = [];

    const includeEntitiesQuery =
      this.buildIncludeEntitiesQuery(includeEntities);

    if (includeEntitiesQuery) queryParts.push(includeEntitiesQuery);

    const propertyFiltersQuery =
      this.buildPropertyFiltersQuery(propertyFilters);

    if (propertyFiltersQuery)
      //                                ___________ => removes trailing '&'
      queryParts.push(propertyFiltersQuery.slice(0, -1));

    if (options?.page)
      queryParts.push(ApiService.toQueryPageString(options.page));
    if (options?.pageSize)
      queryParts.push(ApiService.toQueryPageSizeString(options.pageSize));
    if (options?.search)
      queryParts.push(ApiService.toQuerySearchString(options.search));
    if (options?.order)
      queryParts.push(ApiService.toQueryOrderString(options.order));
    if (options?.filter)
      queryParts.push(ApiService.toQueryPropertyString(options.filter));

    return queryParts.join('&');
  }

  private static toQueryPageString(page: number): string {
    return `page=${page}`;
  }

  private static toQueryPageSizeString(pageSize: number): string {
    return `pageSize=${pageSize}`;
  }

  private static toQuerySearchString(search: string): string {
    return `search=${encodeURIComponent(search)}`;
  }

  private static toQueryOrderString(order: ApiOptionsSort): string {
    const queryParts: string[] = [];

    for (const field in order) {
      if (Object.hasOwnProperty.call(order, field)) {
        queryParts.push(`order[${field}]=${order[field]}`);
      }
    }
    return queryParts.join('&');
  }

  private static toQueryPropertyString(filter: ApiOptionsFilter): string {
    const queryParts: string[] = [];

    if (!filter) {
      return '';
    }

    for (const field in filter) {
      if (!Object.hasOwnProperty.call(filter, field)) {
        continue;
      }

      const value = filter[field];

      if (Array.isArray(value)) {
        value.forEach(item =>
          queryParts.push(ApiService.appendFilterIfEmpty(field, item, true))
        );
      } else {
        queryParts.push(ApiService.appendFilterIfEmpty(field, value));
      }
    }

    return queryParts.join('&');
  }

  private static appendFilterIfEmpty(
    field: string,
    value: any,
    many: boolean = false
  ): string {
    const operators = Object.entries({
      ...FilterOperator,
      ...DateFilterOperator,
    }).map(foEntry => foEntry[1]);
    const hasFilter = operators.some(operator =>
      field.endsWith(`-${operator}`)
    );
    return `${field}${hasFilter ? '' : '-eq'}${many ? '[]' : ''}=${encodeURIComponent(value)}`;
  }

  protected createPaginatedResult = (
    response: ApiResponse<T>
  ): PaginatedResult<T> => ({
    items: response['hydra:member'],
    totalCount: response['hydra:totalItems'],
    properties: new FilterParser().parse(response),
  });
}
