import { Route } from '@angular/router';
import { DevComponent } from '@features/dev/dev.component';
import { UIComponentsComponent } from '@features/dev/ui-components/ui-components.component';

export const DevRoute: Route = {
  path: 'dev',
  title: 'Development',
  component: DevComponent,
  children: [
    {
      path: 'ui-components',
      component: UIComponentsComponent,
    },
    {
      path: '',
      redirectTo: 'ui-components',
      pathMatch: 'full',
    },
  ],
};
