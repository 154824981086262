<div class="relative size-full flex flex-col overflow-clip">
  <app-particles />
  <section class="bg--default absolute top-0 left-0 bottom-0 right-0 flow py-8 px-2 flex flex-col items-center justify-center gap-y-8 sm:gap-y-12">
    <div class="absolute inset-0 z-10 animate-pulse-slow bg-radial-primary"></div>
    <div class="size-1/3 min-w-44 xl:min-w-60">
      <img class="object-contain size-full"
        [ngSrc]="thumbnail()"
        width="126" height="150"
        alt="flowzilla-gorilla"
        priority 
      />
    </div>

    <div class="z-10 w-full xs:w-[26rem]">
      <ng-content />
    </div>
  </section>
</div>
