import { Directive, input } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { BaseButtonDirective } from './button-base.directive';

/**
 * Defines the possible icons for buttons.
 */
type ButtonIcon = IconProp | undefined;

/**
 * Directive for a standard button.
 * Inherits from BaseButtonDirective to utilize shared logic.
 */
@Directive()
export class ButtonDirective extends BaseButtonDirective {
  /**
   * The icon of the button. Optional.
   */
  icon = input<ButtonIcon>();

  /**
   * Returns the base class name for styling the standard button.
   * @returns {string} The base class name for the standard button.
   */
  protected get buttonClass(): string {
    return 'flkButton';
  }
}
