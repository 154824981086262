import { Component } from '@angular/core';
import { UIButtonsComponent } from '@features/dev/ui-components/buttons/ui-buttons.component';
import { ThemeSwitchComponent } from '@core/components/navbar/theme-switch.component';

@Component({
  standalone: true,
  selector: 'app-ui-components',
  imports: [UIButtonsComponent, ThemeSwitchComponent],
  template: `
    <div class="bg--default p-5">
      <div class="text-end">
        <app-theme-switch />
      </div>
      <app-ui-buttons></app-ui-buttons>
    </div>
  `,
})
export class UIComponentsComponent {}
