import { Route } from '@angular/router';
import { environment } from '@environment/environment';
import { DevRoute } from '@features/dev/dev.route';

export function provideDevRoute(): Route {
  if (environment.production) {
    return {};
  }
  return DevRoute;
}
