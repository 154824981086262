import { Directive, input } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { BaseButtonDirective } from './button-base.directive';

/**
 * Defines the possible icons for buttons.
 */
type ButtonIcon = IconProp;

/**
 * Directive for an icon button.
 * Inherits from BaseButtonDirective to utilize shared logic.
 */
@Directive()
export class IconButtonDirective extends BaseButtonDirective {
  /**
   * Tooltip
   */
  tooltip = input.required<string>();

  /**
   * The icon of the button.
   */
  icon = input.required<ButtonIcon>();
  /**
   * Returns the base class name for styling the icon button.
   * @returns {string} The base class name for the icon button.
   */
  protected get buttonClass(): string {
    return 'flkIconButton';
  }
}
