import { CommonModule } from '@angular/common';
import {
  Component,
  computed,
  EventEmitter,
  input,
  Output,
  signal,
} from '@angular/core';
import {
  FormGroup,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterLink } from '@angular/router';
import { EnterKeyDirective } from '@core/directives/enter-key.directive';
import { AuthUser } from '@core/states/auth/interfaces/auth-user';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { DefaultButtonComponent } from '@shared/components/buttons/default-button/default-button.component';

@Component({
  selector: 'app-login-form',
  standalone: true,
  templateUrl: './login-form.component.html',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    DefaultButtonComponent,
    FontAwesomeModule,
    RouterLink,
    EnterKeyDirective,
    MatCheckboxModule,
  ],
  styles: `
    :host ::ng-deep {
      --mdc-checkbox-state-layer-size: auto;
    }

    :host ::ng-deep :not(.mat-mdc-checkbox-checked) .mdc-label {
      @apply leading-snug text-neutral-700 dark:text-neutral-300;
    }
    :host ::ng-deep .mat-mdc-checkbox-checked .mdc-label {
      @apply text-neutral-600 dark:text-neutral-200;
    }
    :host ::ng-deep .mdc-checkbox:hover {
      .mdc-checkbox__ripple {
        @apply hidden;
      }
    }
    :host ::ng-deep .mat-mdc-checkbox-touch-target {
      @apply size-full opacity-0;
    }
  `,
})
export class LoginFormComponent {
  /**
   * Inputs
   */
  loading = input<boolean>(false);
  emailLabel = 'Email';

  /**
   * Outputs
   */
  @Output() submitEvent = new EventEmitter<Partial<AuthUser>>();

  formGroup: FormGroup;
  hidePassword = signal(true);
  iconClass = computed<IconProp>(() =>
    this.hidePassword() ? ['fas', 'eye'] : ['fas', 'eye-slash']
  );

  constructor(private fb: NonNullableFormBuilder) {
    this.formGroup = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      _remember_me: [false],
    });
  }

  submit(): void {
    this.submitEvent.emit(this.formGroup.value);
  }
}
