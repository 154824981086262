<form
  [formGroup]="formGroup"
  class="flex flex-col !pb-0"
  appEnterKey
  (enterKey)="submit()">
  <mat-form-field class="mb-3 w-full" appearance="outline" floatLabel="always">
    <mat-label>{{ emailLabel }}</mat-label>
    <input matInput formControlName="email" data-cy="email" />
    @if (formGroup.get('email')?.invalid) {
      <mat-error>
        @if (formGroup.get('email')?.getError('email')) {
          <span i18n="@@error.invalid-field"> {{ emailLabel }} is invalid </span>
        } @else {
          <span i18n="@@error.required-field"> {{ emailLabel }} is required </span>
        }
      </mat-error>
    }
  </mat-form-field>

  <mat-form-field class="group w-full" appearance="outline" floatLabel="always">
    <mat-label i18n="@@auth.login.password">Password</mat-label>
    <input
      matInput
      formControlName="password"
      [type]="hidePassword() ? 'password' : 'text'"
      data-cy="password" />
    <fa-icon
      matSuffix
      class="input__suffix"
      [icon]="iconClass()"
      (click)="hidePassword.set(!hidePassword())" />
  </mat-form-field>

  <div class="mb-16 flex max-xs:flex-col xs:items-center justify-between gap-y-3">
    <mat-checkbox color="primary" formControlName="_remember_me">
      <span i18n="@@auth.login.remember" class="ml-1 w-max">Remember me</span>
    </mat-checkbox>

    <div class="max-xs:hidden">
      <ng-container [ngTemplateOutlet]="forgot" />
    </div>
  </div>

  <app-default-button
    [stretch]="true"
    [loading]="loading()"
    (clickEvent)="submit()"
    data-cy="submit"
  >
    Login
    <fa-icon
      [icon]="['fas', 'arrow-right']"
      class="text-white transition-transform duration-300 ease-in-out group-hover:translate-x-2 dark:text-primary-500" 
    />
  </app-default-button>
  <div class="mt-2 xs:hidden">
    <ng-container [ngTemplateOutlet]="forgot" />
  </div>

  <!-- TODO: Disabled for now until feature completion -->
  <ng-template #forgot>
    <a
      class="inline-block w-full text-center text-sm hover:text-primary-400"
      i18n="@@auth.login.forgot"
      [routerLink]="['..', 'password-forgotten']"
    >
      Password forgotten?
    </a>
  </ng-template>
</form>
