<form
  [formGroup]="formGroup"
  class="flex flex-col"
  appEnterKey
  (enterKey)="onSubmit()">
  <mat-form-field class="mb-3 w-full" appearance="outline" floatLabel="always">
    <mat-label>{{ fieldLabel }}</mat-label>
    <input
      matInput
      formControlName="email"
      data-cy="email"
      i18n-placeholder="@@auth.recovery.email" />
    @if (formGroup.get('email')?.invalid) {
      <mat-error>
        @if (formGroup.get('email')?.getError('email')) {
          <span i18n="@@error.invalid-field"> {{ fieldLabel }} is invalid </span>
        } @else {
          <span i18n="@@error.required-field"> {{ fieldLabel }} is required </span>
        }
      </mat-error>
    }
  </mat-form-field>

  <app-default-button
    [stretch]="true"
    [loading]="loading()"
    (clickEvent)="onSubmit()"
    i18n="@@auth.recovery.send"
    >Send email</app-default-button
  >
</form>
