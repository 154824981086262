import { Component } from '@angular/core';
import { ButtonsComponent } from '@features/dev/ui-components/buttons/buttons.component';

@Component({
  standalone: true,
  imports: [ButtonsComponent],
  selector: 'app-ui-buttons',
  template: `
    <div>
      <h1 class="heading-title">Buttons</h1>
      <div class="flex flex-col gap-10">
        <app-my-buttons [appearance]="'primary'" [header]="'Primary'" />
        <app-my-buttons [appearance]="'secondary'" [header]="'Secondary'" />
        <app-my-buttons [appearance]="'tertiary'" [header]="'Tertiary'" />
      </div>
    </div>
  `,
})
export class UIButtonsComponent {}
