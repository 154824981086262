@switch(layoutService.layout()) {
  @case(Layout.ADMIN) {
    <app-layout-admin>
      <router-outlet></router-outlet>
    </app-layout-admin>
  }
  @case(Layout.AUTH) {
    <app-layout-auth>
      <router-outlet></router-outlet>
    </app-layout-auth>
  }
  @default {
    <app-layout-default>
      <router-outlet></router-outlet>
    </app-layout-default>
  }
}
