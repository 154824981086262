import {
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  inject,
  input,
  Renderer2,
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';

/**
 * Defines the possible appearance of buttons.
 * @type {ButtonAppearance}
 */
export type ButtonAppearance = 'primary' | 'secondary' | 'tertiary';

/**
 * Defines the possible variants of buttons.
 * @type {ButtonVariant}
 */
export type ButtonVariant = 'accent' | 'info' | 'default' | 'warn';

/**
 * Base class for button directives that handles common functionality.
 * It includes type and disabled state management, as well as class setting.
 */
@Directive()
export abstract class BaseButtonDirective {
  /**
   * The type of the button. Defaults to 'primary'.
   */
  appearance = input<ButtonAppearance>('primary');

  /**
   * The variant of button. Defaults to 'accent'.
   */
  variant = input<ButtonVariant>('accent');

  /**
   * Determines whether the button is disabled. Defaults to 'false'.
   */
  disabled = input<boolean>(false);

  /**
   * Determines whether the button is loading. Defaults to 'false'.
   * Show a loading spinner inside the button when set to true.
   */
  loading = input<boolean>(false);

  /**
   * The base class name for styling the button.
   */
  private readonly cssClass = `flkButton`;

  /**
   * Abstract method that must be implemented by derived classes
   * to return the base class name used for styling.
   * @returns {string} The base class name for the button.
   */
  protected abstract get buttonClass(): string;

  /**
   * Injects the element reference to access the native DOM element.
   */
  protected readonly el = inject(ElementRef);

  /**
   * Injects the renderer to manipulate the DOM.
   */
  protected readonly renderer = inject(Renderer2);

  /**
   * Gets the native DOM element of the directive.
   * @returns {HTMLElement} The native DOM element.
   */
  protected get element(): HTMLElement {
    return this.el.nativeElement;
  }

  constructor() {
    toObservable(this.disabled).subscribe(() => this.handleDisabledState());
    toObservable(this.loading).subscribe(() => this.handleLoadingState());
  }

  @HostBinding('class')
  public get hostClasses() {
    return {
      [`${this.buttonClass}`]: true,
      [`${this.cssClass}--${this.appearance()}`]: true,
      [`${this.cssClass}--variant-${this.variant()}`]: true,
      [`${this.cssClass}--disabled`]: this.disabled(),
      [`${this.cssClass}--loading`]: this.loading(),
    };
  }

  @HostListener('click', ['$event'])
  public onClick(event: Event): void {
    if (this.disabled() || this.loading()) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
  }

  private handleDisabledState(): void {
    this.setDisabledState();
  }

  private handleLoadingState(): void {
    this.setDisabledState();
  }

  private setDisabledState(): void {
    if (this.disabled() || this.loading()) {
      this.renderer.setAttribute(this.element, 'disabled', 'true');
    } else {
      this.renderer.removeAttribute(this.element, 'disabled');
    }
  }
}
